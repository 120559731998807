import React from "react"
import Layout from "../components/layout"

import { Link } from "gatsby"

import Header1 from "../components/header1"

export default function Pricing() {
  return (
    <Layout fullWave="false" bottomWave="false" callToAction="true">
      <section className="bg-gray-100 py-20" id="pricing">
        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <Header1 headerText="Pricing" />
          <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
            <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="w-full p-8 text-3xl font-bold text-center">
                  LinkFive Access-all-Areas
                </div>
                <div className="h-1 w-full bg-l5-primary-background my-0 py-0 rounded-t"></div>
                <ul className="w-full text-center text-base font-bold">
                  <li className="border-b py-4">
                    Manage your subscriptions, all stores
                  </li>
                  <li className="border-b py-4">
                    Create new subscription, all stores
                  </li>
                  <li className="border-b py-4">Pricing insights, worldwide</li>
                  <li className="border-b py-4">All Charts & Reports</li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-4xl font-bold text-center italic">
                  Free during Beta
                  {/* 0,00€
                  <span className="text-base">/ per user</span> */}
                </div>
                <div className="flex items-center justify-center">
                  <Link to="/contact" className="mx-auto lg:mx-0 hover:underline bg-l5-primary-background text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"  state={{ scheduleDemo: true }}>
                    Get early access!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
